.App {
    text-align: center;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    overflow-y: scroll;
    position: fixed;
    background-color: #ece7ca;
}

body {
    overflow-x: hidden;
    overflow-y: hidden;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
}

input {
    box-sizing: border-box;
    padding: clamp(6px, 2vw, 12px);
    border-radius: 4px;

    border-style: none;
    -webkit-appearance: none;
    -webkit-box-shadow: 0px 0px 0px 1px rgba(62, 62, 62, 0.5);
    box-shadow: 0px 0px 0px 1px rgba(62, 62, 62, 0.5);

    font-weight: 400;
    transition: 200ms;

    cursor: auto;

    background-image: linear-gradient(rgba(0, 0, 0, 0.04), 
    rgba(0, 0, 0, 0.04));

    font-size: clamp(10px, 2vw, 16px);
    color: #191818;
    background-color: transparent;
}

input:focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px rgba(62, 62, 62, 0.5);
}

.inputWHeader {
    display: flex;
    flex-direction: column;
    text-align: start;
    margin: clamp(10px, 10%, 25px) 0px 0px clamp(10px, 2vh, 25px)
}

svg {
    height: 20px;
    vertical-align: text-top;
}

.imgSelector {
    display: flex;
    background-color: lightgray;
    border-color: black;
    border-width: 2px;
    border-radius: 5px;
    border-style: solid;
    margin: 5px auto 15px auto;
    width: 300px;
    height: 300px;
}

.imgSelector:hover {
    background-image: linear-gradient(rgb(0 0 0/10%) 0 0);
}

.imgSelector input{
    display: none;
}

.title {
    color: black;
    font-size: clamp(14px, 80%, 22px);
    font-weight: 700;
}

.font12 {
    font-size: clamp(8px, 80%, 12px);
}

.font14 {
    font-size: clamp(10px, 80%, 14px);
}

.font16 {
    font-size: clamp(12px, 80%, 16px);
}

.font18 {
    font-size: clamp(14px, 80%, 18px);
}

.font24 {
    font-size: clamp(20px, 80%, 24px);
}

span {
    font-size: clamp(12px, 80%, 16px);
}

button {
    cursor: pointer;
    color: #ffffff;
    border-width: 0px;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: clamp(12px, 80%, 22px);
    font-weight: 600;
    transition: 500ms;
}

button:hover {
    background-image: linear-gradient(rgb(0 0 0/5%) 0 0);
}

.discount {
    color: black;
    padding-top: 2px;
    padding-bottom: 10px;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 200ms;

    .container {
        display: flex;
        margin: 12px;
        height: 45px;
    }

    .discountShowcase {
        display: flex;
        justify-content: space-between;
        user-select: none;
        border: none;
        box-sizing: border-box;
        width: 60%;
        height: 100%;
        padding: 12px 12px;
        border-radius: 4px;
        background-color: rgb(201, 201, 201);
        box-shadow: 0px 0px 0px 1px rgba(62, 62, 62, 0.5);
        font-size: 16px;
        font-weight: 400;
        transition: 200ms;

        span {
            font-size: 16px;
        }

        .type{
            font-weight: 500;
        }
    }

    button {
        background-color: #247f24;
        margin: 0 5px;
        padding: 12px 12px;
        height: 100%;
        width: 35%;
    }

    input {
        border: none;
        box-sizing: border-box;
        width: 60%;
        height: 100%;
        padding: 12px 12px;
        border-radius: 4px;
        background-color: white;
        box-shadow: 0px 0px 0px 1px rgba(62, 62, 62, 0.5);
        font-size: 16px;
        font-weight: 400;
        transition: 200ms;
    }
    
    input:focus {
        outline: none;
        box-shadow: 0px 0px 0px 3px rgba(62, 62, 62, 0.5);
    }

}

.infoBox {
    display: none;
    background-color: #0000005f;
    position: absolute;
    top: 0px;
    z-index: 200;
    width: 100%;
    height: 300%;
    transition: 1s
}

.infoBox .borderdDiv{
    display: flex;
    margin: 220px auto;
    flex-direction: column;
    justify-content: space-around;
    height: clamp(160px, 25vw, 280px);
    width: clamp(200px, 25vw, 350px);
    background-color: white;
    font-size: clamp(14px, 25vw, 24px);;
    font-weight: 700;
}

.infoBox .checkMark{
    background-color: transparent;
    width: clamp(50px, 15vw, 125px);
    height: clamp(50px, 15vw, 125px);
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 4px 6px;
    margin-left: auto;
    margin-right: auto
}

.infoBox .checkMark svg {
    width: 100%;
    height: 100%;
}

.logInButton {
    all: unset;
    width: 230px;
    padding-left: 10px;
    height: 45px;
    background-color: white;
    border-color: #000000;
    border-width: 1px;
    border-radius: 5px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: black;
    transition: 500ms;
}

.logInButton:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 10px;
}

.logInBoard {
    border-radius: 16px;
    font-size: 30px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #ece7ca;
    width: 300px;
    height: 200px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center 
}

.offer {
    display: flex;
    justify-content: space-between;
    text-align: start;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    margin-top: 30px;
    margin-bottom: 20px;
    border-radius: 16px;
    padding-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 200ms;
}

.offer:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px;
}

.offer img {
    margin: clamp(5px, 8%, 15px);
    width: clamp(80px, 90%, 160px);
}

.offer span{
    color: black;
    margin-left: clamp(5px, 1%, 15px);
    margin-right: 5px;
    margin-top: clamp(10px, 10%, 15px);
    font-size: clamp(12px, 75%, 18px);
}

.borderdDiv {
    margin-top: 60px;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 200ms;
}

.borderdDiv:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px;
}

.userFormRow{
    display: flex;
    flex-direction: row;
    justify-content: space-around
}

.InstructionBox {
    padding-top: 2px;
    color: black;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 200ms;
}

.CreateOfferForm {
    width: 100%;
    height: 100%;
    text-align: left;
    background-color: transparent;

    .body {
        justify-content: space-between;
        display: flex;
        flex-direction: column;

        .item {
            display: flex;
            margin: 25px 0px 25px 0px;
            max-height: 35px;
            height: 4vh;
            min-height: 30px;
        }

        input{
            font-size: clamp(12px, 20%, 16px);
            width: clamp(95px, 20vw, 140px);
            height: 100%;

            margin: auto 0 auto 0;
        }

        .timeSelect{
            background-color: white;
            width: 72px;
            padding-right: 0px; 
            padding-left: 8px
        }
    }
    
    span{
        color: black;
        font-size: clamp(16px, 100%, 28px);
        font-weight: 600;
        text-align: left;
        margin: auto 20px auto 40px;
    }

    h1{
        color: black;
        font-size: 140%;
        font-weight: 700;
        padding-left: 10px;
    }
}

.Banner {
    width: 100%;
    top: 0px;
}

.Header {
    position: sticky;
    top: 0px;
    z-index: 100;
    display: flex;
    background-color: #000000;
    width: 100%;
    height: 50px;
}

.Header button {
    color: white;
    background-color: transparent;
    border-width: 1px;
    border-radius: 0px;
    border-color: transparent;
    border-style: solid;
    font-size: clamp(10px, 2.5vw, 24px);
    font-weight: 700;
    width: 100%;
    transition: 50ms;
}

.Header button:hover {
    color: white;
    background-color: transparent;
    border-color: beige;
}

.Footer {
    width: 100%;
    margin-top: 100px;
    height: 250px;
    position: relative;
    z-index: 100;
}

.Footer h1 {
    color: black;
    font-size: clamp(16px, 80%, 20px);
}

.Footer span {
    color: black;
    font-size: clamp(12px, 80%, 16px);
    margin-bottom: 20px;
}

.Footer a{
    margin-top: auto;
    margin-bottom: auto;
    color: black;
    font-size: clamp(12px, 80%, 16px);
    text-decoration: none
}