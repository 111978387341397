.LandingPageHeader {
    position: sticky;
    width: 100%;
    top: 0px;
    z-index: 100;
    height: 80px;
    background-color: black;
    display: flex;
    justify-content: space-between;
}

.dropdown {
    width: clamp(150px, 20vw,  250px);
    background-color: transparent;
    height: 100%;
}

.dropdown:hover {
    background-color: rgba(255, 255, 255, 0.04);
}

.dropdownMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 300px;
    background-color: #545454;
    transition: 300ms ease-out
}

.dropdownMenu button {
    width: 100%;
    height: 50px;
    font-size: 18px;
    color: white;
    background-color: transparent;
    border-radius: 0px;
    border-width: 0px;
}

.dropdownMenu.unactive {
    height: 0px;
}

.dropdownMenu.unactive button {
    opacity: 0;
    padding: 0px 0px;
    font-size: 0;
    height: 0px;
    width: 0px;
}

.LandingPageHeader img {
    height: 100%;
    margin: 0 0 0 0 !important;
    border-radius: 10px;
}

.LandingPage{
    text-align: center;
    align-items: center;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    overflow-y: scroll;
    position: fixed;
    background-color: #ece7ca;
}

.LandingPage p {
    text-align: justify;
    font-weight: 600;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.LandingPage span {
    font-weight: 600;
}

.LandingPage .Us .image{
    height: clamp(200px, 40vw, 450px);
    margin: 10px;
}

.LandingPage .Us .image img{
    max-width: 100%;
    max-height: 100%;
    margin: 0;
}

.LandingPage ul {
    text-align: justify;
    font-weight: 600;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.LandingPage h2{
    padding: clamp(10px, 3vw, 15px) 0px clamp(10px, 3vw, 15px) clamp(10px, 3vw, 30px);
    color: white;
    font-size: clamp(32px, 220%, 42px);
}

.LandingPage .title {
    width: 100%;
    text-align: left;
    background-color: black;
}

.LandingPage a {
    display: block;
    text-decoration: none;
    margin-left: auto;
    margin-right: auto;
    line-height: clamp(50px, 20vh, 120px);
    color: #ffffff;
    background-color: #0097b2;
    background-image: linear-gradient(90deg, #0097b200, #7ed957);
    border-width: 0px;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: clamp(24px, 200%, 48px);
    font-weight: 600;
    transition: background-color 500ms ease-in;
}

.LandingPage a:hover {
    background-color: #7ed957;
}

.LandingPage img {
    max-height: 450px;
    margin: 10px;
    border-radius: 10px;
}

.meistTitles {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    font-weight: 700;
    font-size: clamp(18px, 5vw, 48px);
}

.phoneImage {
    display: none;
}

.largeScreenImage {
    margin-left: auto;
    width: 60%;
}
.firstText {
    position: absolute;
    font-size: clamp(20px, 2vw, 28px);
    left: 40px;
}

.firstText p{
    margin: 0px;
}

.gallery {
    overflow-y: hidden;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.gallery .images {
    overflow-x: scroll;
    height: clamp(120px, 50vw, 200px);
    display: flex;
    margin-top: auto;
    margin-bottom: auto;

    scrollbar-width: none;
    -ms-overflow-style: none;
}

.gallery .images img {
    height: 100%;
    border-radius: 5px;
    margin: 0 10px;
    opacity: 0.5;
}

.gallery .images .selected {
    opacity: 1;
}

.gallery .images::-webkit-scrollbar {
    display: none;
}

.gallery .showcase {
    display: flex;
    margin-bottom: 10px;
    width: 100%;
    height: clamp(300px, 80vw, 500px);
    justify-content: space-evenly;
}

.gallery .showcase img {
    max-width: 100%;
    max-width: 100%;
    object-fit: contain;
    border-radius: 10px;
    margin: 0;
}

.gallery button {
    background-color: transparent;
    font-size: clamp(24px, 6vw, 32px);
    margin-right: 40px;
    margin-left: 40px;
    margin-top: 5px;
    color: black;
}

@media (max-width: 1000px) {
    .meistTitles {
        text-align: left;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        font-weight: 600;
    }
    .phoneImage {
        display: inline;
        width: 300px;
        margin-top: 15px;
    }
    .largeScreenImage {
        display: none;
    }
    .firstText {
        position: static;
        left: 0px;
    }
    .firstText p{
        margin: auto;
    }
}